import React from 'react';
import ObfuscationLink from '../seo/ObfuscationLink';
import banner_leaderboard from '../../images/banners/leaderboard-motion.mp4';

export default function BannerLeaderboard() {

    return (
        <ObfuscationLink encodeUrl={'https://chilowe.com/selection/micro-aventures-avec-guide/#ae223'}>
            <video autoPlay loop muted playsInline>
                <source src={banner_leaderboard} type="video/mp4"></source>
            </video>
        </ObfuscationLink>
    )

}