import React from 'react';
import ObfuscationLink from '../seo/ObfuscationLink';
import banner_portrait from '../../images/banners/rectangle-verticale.mp4';
import IsMobile from '../Layout/IsMobile';

import * as styles from "./BannerPortrait.module.scss";

export default function BannerPortrait() {
    if (!IsMobile()) {
        return (
            <div className='sidebar-section'>
                <div className={styles.videoSection}>
                    <ObfuscationLink encodeUrl={'https://chilowe.com/selection/micro-aventures-avec-guide/#ae223'}>
                        <video autoPlay loop muted playsInline>
                            <source src={banner_portrait} type="video/mp4"></source>
                        </video>
                    </ObfuscationLink>
                </div>
            </div>
        )
    }
    return null

}


