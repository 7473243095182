// extracted by mini-css-extract-plugin
export var bgColor = "UrbanAreaCard-module--bg-color--bef10";
export var bgDarkGreen = "UrbanAreaCard-module--bg-dark-green--18e55";
export var bgDarkGreen01 = "UrbanAreaCard-module--bg-dark-green-01--8b3bf";
export var bgGradientGreen = "UrbanAreaCard-module--bg-gradient-green--75201";
export var bgGradientLyellow = "UrbanAreaCard-module--bgGradientLyellow--8f75d";
export var bgGreen = "UrbanAreaCard-module--bg-green--03479";
export var bgGrey = "UrbanAreaCard-module--bg-grey--0463a";
export var bgLightBlue = "UrbanAreaCard-module--bg-light-blue--5bf3a";
export var bgLightGreen = "UrbanAreaCard-module--bg-light-green--fffa9";
export var bgLightGreenColor = "UrbanAreaCard-module--bg-light-green-color--40179";
export var bgLightGrey = "UrbanAreaCard-module--bg-light-grey--abf6d";
export var bgLightYellow = "UrbanAreaCard-module--bg-light-yellow--068db";
export var bgMint = "UrbanAreaCard-module--bg-mint--938c3";
export var bgOrange = "UrbanAreaCard-module--bg-orange--6de2c";
export var card = "UrbanAreaCard-module--card--6636d";
export var defaultImg = "UrbanAreaCard-module--default-img--c9353";
export var green = "UrbanAreaCard-module--green--3e3bd";
export var grey = "UrbanAreaCard-module--grey--12f5a";
export var hikeUrbanareaBackgroundImg = "UrbanAreaCard-module--hike-urbanarea-background-img--16ccf";
export var hikeUrbanareaCard = "UrbanAreaCard-module--hike-urbanarea-card--d762d";
export var img = "UrbanAreaCard-module--img--97d8a";
export var imgimg = "UrbanAreaCard-module--imgimg--afbac";
export var lightBlue = "UrbanAreaCard-module--light-blue--98e2e";
export var lightGreen = "UrbanAreaCard-module--light-green--ed3c4";
export var lightYellow = "UrbanAreaCard-module--light-yellow--baa94";
export var mint = "UrbanAreaCard-module--mint--9a4e1";
export var orange = "UrbanAreaCard-module--orange--53b19";