import React from 'react';
import { Link, graphql, PageProps } from 'gatsby';
import Breadcrumb from '../components/Layout/breadcrumb';
import { StaticImage } from "gatsby-plugin-image";
import { Col, Row, Container, Accordion } from 'react-bootstrap';

import Meta from '../components/Layout/head';
import Layout from '../components/Layout/layout';
import ParkCell from '../components/Park/ParkCard';
import HikeCell from '../components/Hike/HikeCard';
import UrbanAreaCell from '../components/HikeUrbanarea/UrbanAreaCard';
import DownloadApp from '../components/sidebar/download-app';
import Search from '../components/search/search';

import media from '../images/photos/cascade-de-la-fraiche.jpg';
import BannerPortrait from '../components/Banners/BannerPortrait';
import BannerLeaderboard from '../components/Banners/BannerLeaderboard';

import * as styles from './randonnees.module.scss';

export const Head = () => (
  <Meta 
    title={`Randonnée > Top des Meilleurs lieux où Randonner`} 
    description={`Envie de voyage ? Retrouvez toutes les meilleures randonnées au cœur des plus belles réserves naturelles de France. Dénivelé, difficulté, …`}
    lang='fr'
    media={media}
    pathname='/randonnees/'>
  </Meta> 
)

export default function HikingPage({ data, location }: PageProps<Queries.HikingPageQuery>) {

  const breadcrumbArray = [['/randonnees/','Randonnées']];

  const parks = data.allContentfulPark;
  const hikeUrbanAreas = data.allContentfulHikeUrbanArea;
  const hikes = data.allContentfulHike;

  return (
    <Layout>
      <Container fluid>
        <Row className='banner-leaderboard'>
          <Col sm='12' md={{span:10, offset:1}} lg={{span:8, offset:2}}>
            <BannerLeaderboard/>
          </Col>
        </Row>
      </Container>
      <Container className={styles.hike__heroImage}>
        <Row>
          <Col>                
            <div className={styles.banner}>
              <StaticImage 
                    src="../images/photos/italie-bellagio.jpg"
                    alt="Lillaz au parc national du Grand Paradis"
                    placeholder="blurred"
                    className={styles.banner__cover}
              />
              <div className={styles.banner__bgColor}></div>
              <div className={styles.banner__title}>
                <h1 className='text-white'>Randonnées</h1>
                <p className='text-white'>Planifiez votre randonnée et voyagez au cœur des plus belles réserves naturelles</p>
                <Container>
                  <Row>
                    <Col lg={{span:4, offset:4}} md={{span:8, offset:2}} sm={{span:10, offset:1}}>
                      <Search className="lg-input"/>
                    </Col>
                  </Row>
                </Container>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <Container className="mb-4 green">
        <Row>
          <Col md={9}>
            <Row className='my-2'>
              <Col>
                <Breadcrumb location={location} itemsArray={breadcrumbArray} />
                <h2>Où réaliser votre prochain voyage en nature ?</h2>
                <p>Voici quelques unes des plus belles réserves naturelles de France à découvrir sur Rheaparks pour randonner. </p>
                <Row>
                      {
                        parks.nodes.map((park) => (
                            <Col key={park.id} xs='12' sm='6' md='6' lg='4'>
                              <ParkCell park={park} />
                            </Col>
                        ))
                      } 
                </Row>
              </Col>
            </Row>
            <Row className='my-2'>
              <Col>
                <h2>Les meilleures villes pour randonner en nature</h2>
                <p>Envie de voyager et partir en nature ? Voici les meilleures destinations pour randonner.</p>
                <Row className='gx-0'>
                      {
                        hikeUrbanAreas.nodes.map((urbanArea) => (
                            <Col key={urbanArea.id} xs='6' sm='6' md='6' lg='3'>
                              <UrbanAreaCell {...urbanArea} />
                            </Col>
                        ))
                      } 
                </Row>
              </Col>
            </Row>
            <Row className='my-4'>
              <Col lg={7}>
                <h2>6 Raisons de mixer voyage et randonnées</h2>
                <p>Partir en voyage au cœur des réserves naturelles, offre des moments inoubliables au vécu et aux émotions uniques. Les avantages de réaliser ce type de voyage sont multiples :</p> 
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header as="p"><b>1. Voyager de manière eco-responsable</b></Accordion.Header>
                    <Accordion.Body>
                      <p>Partir dans les réserves françaises et européennes permet de se dépayser dans le respect de la nature, 
                      en réduisant ses trajets et ses activités polluantes.</p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header as="p"><b>2. Observer la faune et la flore</b></Accordion.Header>
                    <Accordion.Body>
                      <p>Randonner au cœur des réserves naturelles et le meilleur moyen de renouer avec la nature. 
                        Découvrir toute sorte de fleurs au printemps, observer au loin une multitude d’animaux vivre en liberté… 
                        et profiter d’une ambiance unique lors de chaque saison.</p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header as="p"><b>3. Combiner voyage et sport</b></Accordion.Header>
                    <Accordion.Body>
                      <p>La randonnée reste un sport doux et accessible au plus grand nombre, 
                        permettant de prendre soin de sa santé et de profiter de panoramas exceptionnels.</p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header as="p"><b>4. Se surpasser</b></Accordion.Header>
                    <Accordion.Body>
                      <p>La randonnée est un bel exemple de sport où l’on peut se donner des objectifs plus ou moins difficiles à atteindre. 
                        Forçant ainsi parfois à se surpasser pour découvrir un paysage toujours plus grand. 
                        De quoi ressortir fier de soi, aux souvenirs fort en émotions.</p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="4">
                    <Accordion.Header as="p"><b>5. S'évader et se sentir libre</b></Accordion.Header>
                    <Accordion.Body>
                      <p>Entre le sport, les efforts et les panoramas qui s’ouvrent petits à petits face à nous, 
                        ces voyages permettent de se vider systématiquement la tête avec une agréable sensation de liberté.</p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="5">
                    <Accordion.Header as="p"><b>6. Profiter de magnifiques panoramas</b></Accordion.Header>
                    <Accordion.Body>
                      <p>Et finalement, forcément, la randonnée et le meilleur moyen pour découvrir une infinité de splendides paysages naturels que nous offre notre belle planète. Il n’y a qu’à suivre les sentiers… et nous y sommes !</p>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </Col>

              <Col lg={5}>
                <StaticImage src="../images/photos/cascade-de-la-fraiche.jpg" alt="Parc National de la Vanoise" className="h-100 deleteTablet rounded-md"/>
              </Col>
            </Row>
            <Row className='my-5'>
              <h2 className='pb-3'>Top des meilleurs sentiers de randonnées à faire</h2>
              {            
                hikes.nodes.map((hike) => (
                    <Col xs='12' sm='6' md='6' lg='4' className='mb-3' key={hike.id}>
                        <HikeCell {...hike} />
                    </Col>
                ))
              }
            </Row>
            <Row className='my-4'>
              <h2>Quels chemins de randonnée pédestre choisir ?</h2>
              <Col md={12} lg={7} className="bg-light-yellow rounded-md py-4 mt-4 px-5">
                <h3>Randonnées dans les Pyrénées</h3>
                <p>Parmi les destinations les plus réputées pour la randonnée, nous retrouvons les Pyrénées : 
                  cette belle chaîne de montagne en la France et l’Espagne réservant un nombre innombrable de magnifiques chemins de randonnées. 
                  Parmi celle-ci nous retrouvons la plus connue le <Link to='/france/randonnee/cirque-de-gavarnie/'>Cirque de Gavarnie</Link> donnant vue à la plus haute cascade d’Europe de 422 m. 
                  Et ses voisins le <Link to='/france/randonnee/cirque-de-troumouse-depuis-heas/'>Cirque de Troumouse</Link> et le <Link to='/france/randonnee/cirque-d-estaube/'>Cirque d’Estaubé</Link>. Au cœur des Pyrénées Ariégeoises, l’on retrouve aussi la randonnée vers la <Link to='/france/randonnee/cascade-d-ars/'>Cascade d’Ars</Link> ou encore le <Link to='/france/randonnee/mont-valier/'>Mont Valier</Link>.
                </p>

                {/* Rajouter plus tard les randonnées des Pyrénées Catalanes. */}
              </Col>
              <Col md={12} lg={5} className="mt-4">
                <StaticImage src="../images/photos/pyrenees-etang-bassies.jpg" alt="Pyrénées Ariégeoises" className="w-100 rounded-md"/>
              </Col>
            </Row>
            <Row className='my-4'>
              <Col md={12} lg={7} className="bg-light-yellow rounded-md py-4 mt-4 px-5">
                <h3>Randonnées dans les Cévennes</h3>
                <p>Au cœur des Cévennes, l’on retrouve aussi de splendides randonnées avec l’incontournable randonnée vers le « Mont Aigoual », 
                  le « Circuit de la Corniche » ou encore la « Cascade de Runes ». 
                  La chaîne de montagne des Cévennes et notamment particulièrement appréciée pour ses multiples GR®, 
                  et notamment le GR®70 - Chemin de Stevenson. 
                  À proximité du parc National des Cévennes nous retrouvons aussi le réputé « Cirque de Navacelles » offrant de beaux paysages.
                </p>
              </Col>
              <Col md={12} lg={5}>
              </Col>
            </Row>
            <Row className='my-4'>
              <Col md={12} lg={7} className="bg-light-yellow rounded-md py-4 mt-4 px-5">
                <h3>Randonnées en famille</h3>
                <p>Au cœur de chaque réserve naturelle se trouvent de belles randonnées familiales à réaliser. 
                  Ni trop longues, ni trop difficiles, tout en restant remarquables, l’on retrouve notamment ces différentes randonnées parfaites en famille :</p>
                <ul>
                  <li>Cirque de Gavarnie, le <Link to='/france/randonnee/lac-d-isaby/'>lac d’Isady</Link> ou encore le <Link to='/france/randonnee/lac-de-suyen/'>lac de Suyen</Link> (Pyrénées)</li>
                  <li><Link to='/france/randonnee/calanque-de-port-pin/'>Calanque d’En-Vau par Port-Pin</Link> (Calanques)</li>
                  <li><Link to='/france/randonnee/balade-a-saint-julien-la-plage/'>Balade à Saint-Julien la plage</Link> ou <Link to='/france/randonnee/sentier-des-sireniens/'>le sentier des Siréniens</Link> (Verdon)</li>
                  <li><Link to='/france/randonnee/bois-de-laye/'>Bois de Laye</Link> ou le <Link to='/france/randonnee/tour-des-lacs-de-bellecombe/'>Tour des lacs de Bellecombe</Link> (Vanoise)</li>
                  <li><Link to='/france/randonnee/presqu-ile-de-giens-ouest/'>Presqu’île de Giens</Link> et le <Link to='/france/randonnee/tour-de-l-ile-de-port-cros/'>tour de l’île de Port-Cros</Link> (Port-Cros)</li>
                  <li><Link to='/france/randonnee/cascade-de-l-artigue/'>Cascade de l’Artigue</Link> (Ariège - Pyrénées)</li>
                  <li><Link to='/france/randonnee/sommet-drouvet-et-lacs-d-orcieres-telemix/'>Les lacs d’Orcières</Link>, le <Link to='/france/randonnee/refuge-des-bans/'>refuge des Bans</Link> ou encore <Link to='/france/randonnee/lac-de-la-douche/'>le lac de la Douche</Link> (Écrins - Alpes)</li>
                </ul> 
                <p>Et ce n’est q’un court échantillons, de plusieurs centaines de randonnées à réaliser en famille.</p> 
              </Col>
              <Col md={12} lg={5}>
              </Col>
            </Row>
            <Row>
              <Col md={12} lg={7} className="bg-light-yellow rounded-md py-4 mt-4 px-5">
                <h3>Circuits de randonnée sur plusieurs jours</h3>
                <p>Il existe une grande quantité de grandes randonnées (GR®) à réaliser sur plusieurs jours allant d’une cinquantaine de kilomètres et ce jusqu’à plusieurs centaines de kilomètres. 
                  Pour ceux qui cherchent à vivre une expérience unique en réalisant une randonnée sur plusieurs étapes, 
                  que ce soit en bivouac ou en passant de refuges en refuges, voici quelques uns des GR® les plus connus :</p>
                <ul>
                  <li>Randonnée GR® 20 - Traversée de la Corse (Fra Li Monti - 180 km)</li>
                  <li>Randonnée GR® TMB, le Tour du Mont-Blanc (215 km)</li>
                  <li>Randonnée GR® 10 - Traversé des Pyrénées (920 km)</li>
                  <li>Randonnée GR® 65 - Chemin de Compostelle (930 km)</li>
                  <li>Randonnée GR® 70 - Chemin de Stevenson (207 km)</li>
                </ul> 
              </Col>

              <Col md={12} lg={5}>
              </Col>
          </Row>
          </Col>


          
          <Col md={3} className="mt-4">
              <DownloadApp />
              <BannerPortrait />
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export const HikingPageQuery = graphql`
  query HikingPage {
    allContentfulPark(filter: {node_locale: {eq: "fr"}}) {
      ...AllParksList
    }
    allContentfulHikeUrbanArea(filter: {node_locale: {eq: "fr"}}) {
      ...AllHikesUrbanAreaList
    }
    allContentfulHike(
      filter: {slug: {in: [ "cap-taillat", "cirque-de-gavarnie", "lac-de-gaube", "calanque-d-en-vau-par-port-pin", "lac-du-lauvitel", "falaises-de-devenson", "presqu-ile-de-giens-ouest", "le-saut-du-laire", "breche-de-roland", "le-vieux-chaillol", "cirque-de-troumouse-depuis-heas", "sentier-blanc-martel", "sentier-de-l-imbut", "quinson-et-le-sentier-du-garde-canal", "cadieres-de-brandis", "col-de-la-vanoise", "bois-de-laye", "lac-blanc-et-refuge-peclet-polset", "tour-des-lacs-de-bellecombe", "le-chemin-des-orris", "pic-du-mont-calm-et-pique-d-estats", "cascade-d-ars", "mont-valier", "les-claux-a-moustiers-sainte-marie"]}, node_locale: {eq: "fr"}}
      sort: {id: ASC}
    ) {
        ...AllHikesList
    }
}`