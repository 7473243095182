import React from "react";
import { Link } from 'gatsby';
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import countryDestination from "../country/country-destination";

import illustration from '../../images/default-illustration.svg';

import * as styles from "./UrbanAreaCard.module.scss";


export default function UrbanAreaCell(urbanArea: Queries.HikesUrbanAreaListFragment) {

    const Cover = () => {
        const urbanAreacover = urbanArea?.cover;
        const imgCover = getImage(urbanAreacover);
        const imgAlt = urbanAreacover?.description;

        if(imgCover) {
            return <GatsbyImage image={imgCover} className={styles.img} alt={`${imgAlt}`} />
        } else {
            return <img src={illustration} alt="Illustration paysage de randonnée" className={`${styles.img} ${styles.defaultImg} `} />
        }
    }

        if (urbanArea) {
            // Création des variables Contentful appelé via les Props et mise en page
            const urbanAreaSlug = urbanArea?.slug;
            const title = urbanArea?.title;
            const country = countryDestination(urbanArea.countryCode);

            return (
                    <div className={styles.hikeUrbanareaCard}>      
                        <Link to={`/${country}/randonnees/${urbanAreaSlug}/`}>
                            <div className={styles.bgColor}></div> 
                            <Cover />                                             
                            <h3>{title}</h3> 
                        </Link>     
                    </div>
            );
        } 
        return null;

}
