import React from 'react';
import IsMobile from '../Layout/IsMobile';

import icon_light from '../../images/icons/icon_app_light.svg';
import appstore_qrcode from '../../images/qr-code-appstore.png';


export default function DownloadApp() {
    if(!IsMobile()) {
        return(
            <div className="text-center wdgt-dark">
                <img src={icon_light} alt="Icône d'application Rheaparks" className="wdgt-icon-app"/>
                <p className="wdgt-title mb-0">#Rheaparks</p>
                <div>
                    <p className="wdgt-title mb-0 mt-3">Télécharger l'application</p>
                    <p className="mb-3">100% GRATUITE</p>
                </div>
                <img src={appstore_qrcode} alt="QR Code Application Rheaparks - AppStore" className="qr-code"/>
                <p className="mb-0 mt-2"><small>Scannez le QR Code pour accéder directement à l'appStore</small></p>
            </div>
        )
    }
    return null
}