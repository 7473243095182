import React from 'react';

export default function IsMobile() {
    // https://www.npmjs.com/package/react-qr-code
    let [isMobile, setIsMobile] = React.useState(false);

    const handleResize = () => {
        if (typeof window !== "undefined") {
            setIsMobile(window.innerWidth < 768);
        }    
    }
    
    // Wait the input value and then redirect to the search page
    React.useEffect(() => {
        if (typeof window !== "undefined") {
            setIsMobile(window.innerWidth < 768);
        }  
        window.addEventListener("resize", handleResize, false);
    }, []);

    return isMobile;
}